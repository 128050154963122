import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import Layout from '../../../components/layout'
import Seo from "../../../components/seo"

export default function spaceComic() {
    return (
        <Layout bodyClass="comics">
            <Seo title="AWRA COMICS - End FGM" />
            <article className='container'>
                <header className='article-header'>
                    <h1>Women in Space.</h1>
                </header>
                <section className='grid'>
                    <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/IGE1.jpg?ssl=1" alt="Kids at school think I'm stupid because I said I like spaceships." placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} width={800} aspectRatio={1 / 1} />
                    <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/IGE2.jpg?ssl=1" alt="They said only boys like spaceships." placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} width={800} aspectRatio={1 / 1} />
                    <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/IGE3.jpg?ssl=1" alt="Do you like spaceships less because kids think it's stupid? - No. Know why? - Why? - Because when I grow up, I am going up there. - Well Kawkab, you know what I think? - What, Mama?" placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} className='-full' />
                    <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/K2E-scaled.jpg?ssl=1" alt="I think you can, and I think you will go up there when you grow up. - And I did, Mama." placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} className='-full' />
                </section>
                <section className='grid'>
                    <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/IGA2.jpg?ssl=1" alt="They said only boys like spaceships." placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} width={800} aspectRatio={1 / 1} />
                    <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/IGA1.jpg?ssl=1" alt="Kids at school think I'm stupid because I said I like spaceships." placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} width={800} aspectRatio={1 / 1} />
                    <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/IGA3.jpg?ssl=1" alt="Do you like spaceships less because kids think it's stupid? - No. Know why? - Why? - Because when I grow up, I am going up there. - Well Kawkab, you know what I think? - What, Mama?" placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} className='-full' />
                    <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/K2A.jpg?ssl=1" alt="I think you can, and I think you will go up there when you grow up. - And I did, Mama." placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} className='-full' />
                </section>
            </article>
        </Layout>
    )
}
